import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { BLACK } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, SECTION_HEIGHT, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, IMAGE_BASE_URL, OUTER_URLS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import MobileLineLinkButton from '../common/MobileLineLinkButton';
import Outlink from '../common/Outlink';
import SectionTitleSet from '../common/SectionTitleSet';
import FourPawsListItemDesktop from '../fourPawsSection/FourPawsListItemDesktop';
import FourPawsListItemMobile from '../fourPawsSection/FourPawsListItemMobile';

const FOURPAWS_BG_DESKTOP_URL = `${IMAGE_BASE_URL}/fourpaws/fourpaws_image.png`;
const FOURPAWS_BG_MOBILE_URL = `${IMAGE_BASE_URL}/fourpaws/fourpaws_image_mob.png`;

const FourPawsSection = () => {
	const sectionRef = useRef<HTMLElement>(null);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.White,
	});

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.FOURPAWS} />
			<Wrapper id={ANCHOR_IDS.FOURPAWS} ref={sectionRef}>
				<Box>
					<TitleWrapper>
						<SectionTitleSet
							title="Four Paws"
							description={
								<>
									<span css={showOnDesktopStyle}>
										반려동물에게 그리고 남겨진 보호자들에게 마지막으로 해줄 수 있는 펫닥의 마지막 서비스
										<br />
										픽업 서비스부터 개인 화장, 그리고 펫로스 증후군 케어까지 반려동물의 올바른 장례 문화를 선도합니다.
									</span>
									<span css={showOnMobileStyle}>
										반려동물과 남겨진 보호자를 위해
										<br />
										펫닥이 해줄 수 있는 마지막 선물
									</span>
								</>
							}
							textAlign="LEFT"
							color="BLACK"
						/>
					</TitleWrapper>
					<Outlink text="홈페이지 바로가기" href={OUTER_URLS.FOURPAWS} color={BLACK} />
					<MobileButtonWrapper>
						<MobileLineLinkButton link={OUTER_URLS.FOURPAWS} type="BLACK">
							홈페이지 바로가기
						</MobileLineLinkButton>
					</MobileButtonWrapper>
					<FourPawsListItemDesktop />
					<FourPawsListItemMobile />
				</Box>
			</Wrapper>
		</>
	);
};

export default FourPawsSection;

const Wrapper = styled.section`
	position: relative;
	background: url(${FOURPAWS_BG_MOBILE_URL}) no-repeat center;
	background-size: cover;
	padding: ${pxToRem(80)} 0;

	${getDesktopStyle(css`
		position: relative;
		padding: ${pxToRem(112)} 0;
		background: url(${FOURPAWS_BG_DESKTOP_URL}) no-repeat center;
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		height: ${pxToRem(SECTION_HEIGHT)};
		background-size: auto 100%;
	`)}
`;

const Box = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const TitleWrapper = styled.div`
	width: 100%;

	${getDesktopStyle(css`
		margin: 0 auto ${pxToRem(32)};
	`)}
`;

const MobileButtonWrapper = styled.div`
	margin-top: ${pxToRem(28)};
	text-align: center;
	z-index: 2;
`;
