import {
	RAINBOW_BLUE,
	RAINBOW_GREEN,
	RAINBOW_NAVY,
	RAINBOW_ORANGE,
	RAINBOW_PURPLE,
	RAINBOW_YELLOW,
	RED,
} from '../constants/colors';

export interface HistoryDataItem {
	id: number;
	year: string;
	color: string;
	record: { id: number; date: string; text: string[] }[];
}

export const historyDataDesktop = [
	{
		id: 0,
		year: '설립 ~ 2020',
		color: RED,
		record: [
			{ id: 0, date: '15.12', text: ['펫닥 안드로이드 APP 출시'] },
			{ id: 1, date: '16.01', text: ['펫닥 IOS APP 출시'] },
			{ id: 2, date: '16.03', text: ['펫닥 법인 설립'] },
			{ id: 3, date: '17.06', text: ['퍼스트 펭귄 기업 선정'] },
			{ id: 4, date: '17.07', text: ['서울시 수의사회 MOU 체결'] },
			{ id: 5, date: '18.01', text: ['바른 입양 캠페인 핑크박스 행사'] },
			{ id: 6, date: '18.09', text: ['Petdoc V+lab 출시'] },
			{ id: 7, date: '19.04', text: ['Petdoc V+ad 런칭'] },
			{ id: 8, date: '19.06', text: ['삼성전자, 서울시 수의사회와 3자 MOU체결'] },
			{ id: 9, date: '19.07', text: ['Petdoc V+care 본점 오픈'] },
			{ id: 10, date: '20.01', text: ['경기도수의사회 MOU'] },
			{ id: 11, date: '20.03', text: ['Petdoc V+care 부산점 오픈'] },
			{ id: 12, date: '20.05', text: ['강원대학교 임상수의사회 MOU'] },
			{ id: 13, date: '', text: ['대전시 수의사회 MOU'] },
			{ id: 14, date: '', text: ['충청북도 수의사회 MOU'] },
			{ id: 15, date: '20.06', text: ['아기유니콘 200 기업 선정'] },
			{ id: 16, date: '20.07', text: ['부산광역시수의사회 MOU'] },
			{ id: 17, date: '', text: ['대구시 수의사회 MOU'] },
			{ id: 18, date: '', text: ['인천시 수의사회 MOU'] },
		],
	},
	{
		id: 1,
		year: '2021 ~ 현재',
		color: RED,
		record: [
			{ id: 0, date: '21.08',   text: ['포포즈 부산점 오픈'] },
			{ id: 1, date: '21.09',   text: ['포포즈 광주점 오픈'] },
			{ id: 2, date: '22.03',   text: ['포포즈 김포점 오픈'] },
			{ id: 3, date: '22.09',  text: ['포포즈 양주점 오픈'] },
			{ id: 4, date: '22.10',  text: ['포포즈 세종점 오픈'] },
			{ id: 5, date: '23.01',  text: ['포포즈 화성점 오픈'] },
			{ id: 6, date: '23.06',  text: ['조선일보 선정 \'국가 브랜드 대상\''] },
			{ id: 7, date: '23.09',  text: ['서울시수의사협회 인증'] },
			{ id: 8, date: '24.04',   text: ['한국모바일서비스 \'멍과함께\' MOU 체결'] },
			{ id: 9, date: '',  text: ['포인핸드 MOU 체결'] },
			{ id: 10, date: '24.06',  text: ['조선일보 선정 \'소비자 추천 1위 브랜드\''] },
			{ id: 11, date: '', text: ['KT MOU 체결'] },
			{ id: 12, date: '', text: ['SJ산림조합상조 MOU 체결'] },
			{ id: 13, date: '24.08',  text: ['교원라이프 MOU 체결'] },
			{ id: 14, date: '24.09',  text: ['에이치와이 인수'] },
			{ id: 15, date: '',  text: ['신한카드 MOU 체결'] }
		],
	},
	/*
	{
		id: 2,
		year: '2018',
		color: RAINBOW_YELLOW,
		record: [
			{ id: 0, date: '01', text: ['바른 입양 캠페인 핑크박스 행사'] },
			{ id: 1, date: '05', text: ['교보 라이프 플래닛 MOU 체결'] },
			{ id: 2, date: '09', text: ['Petdoc V+lab 출시'] },
		],
	},
	{
		id: 3,
		year: '2019',
		color: RAINBOW_GREEN,
		record: [
			{ id: 0, date: '04', text: ['Petdoc V+ad 런칭'] },
			{ id: 1, date: '06', text: ['삼성전자, 서울시 수의사회와 3자 MOU체결'] },
			{ id: 2, date: '', text: ['아람휴비스 주식회사 MOU체결'] },
			{ id: 3, date: '07', text: ['Petdoc V+care 본점 오픈'] },
			{ id: 4, date: '', text: ['경남동물병원협회 MOU 체결'] },
			{ id: 5, date: '09', text: ['태국 Fly Goose와 수출 계약 체결'] },
		],
	},
	{
		id: 4,
		year: '2020',
		color: RAINBOW_BLUE,
		record: [
			{ id: 0, date: '01', text: ['경기도수의사회 MOU'] },
			{ id: 1, date: '03', text: ['Petdoc V+care 부산점 오픈'] },
			{ id: 2, date: '05', text: ['강원대학교 임상수의사회 MOU', '대전시 수의사회 MOU'] },
			{ id: 3, date: '', text: ['충청북도 수의사회 MOU'] },
			{ id: 4, date: '06', text: ['아기유니콘 200 기업 선정'] },
			{ id: 5, date: '07', text: ['부산광역시수의사회 MOU', '대구시 수의사회 MOU'] },
			{ id: 6, date: '', text: ['인천시 수의사회 MOU'] },
		],
	},
	{
		id: 5,
		year: '2021',
		color: RAINBOW_NAVY,
		record: [
			{ id: 0, date: '07', text: ['시리즈 C 투자 유치'] },
			{ id: 1, date: '12', text: ['Petdoc V+partners 런칭'] },
		],
	},
	{
		id: 6,
		year: '2022',
		color: RAINBOW_PURPLE,
		record: [{ id: 0, date: '06', text: ['Petdoc Four Paws 예정'] }],
	},
	*/
];

export const historyDataMobile = [
	{
		id: 0,
		year: '설립 ~ 2020',
		color: RED,
		record: [
			{ id: 0, date: '15.12', text: ['펫닥 안드로이드 APP 출시'] },
			{ id: 1, date: '16.01', text: ['펫닥 IOS APP 출시'] },
			{ id: 2, date: '16.03', text: ['펫닥 법인 설립'] },
			{ id: 3, date: '17.06', text: ['퍼스트 펭귄 기업 선정'] },
			{ id: 4, date: '17.07', text: ['서울시 수의사회 MOU 체결'] },
			{ id: 5, date: '18.01', text: ['바른 입양 캠페인 핑크박스 행사'] },
			{ id: 6, date: '18.09', text: ['Petdoc V+lab 출시'] },
			{ id: 7, date: '19.04', text: ['Petdoc V+ad 런칭'] },
			{ id: 8, date: '19.06', text: ['삼성전자, 서울시 수의사회와 3자 MOU체결'] },
			{ id: 9, date: '19.07', text: ['Petdoc V+care 본점 오픈'] },
			{ id: 10, date: '20.01', text: ['경기도수의사회 MOU'] },
			{ id: 11, date: '20.03', text: ['Petdoc V+care 부산점 오픈'] },
			{ id: 12, date: '20.05', text: ['강원대학교 임상수의사회 MOU'] },
			{ id: 13, date: '', text: ['대전시 수의사회 MOU'] },
			{ id: 14, date: '', text: ['충청북도 수의사회 MOU'] },
			{ id: 15, date: '20.06', text: ['아기유니콘 200 기업 선정'] },
			{ id: 16, date: '20.07', text: ['부산광역시수의사회 MOU'] },
			{ id: 17, date: '', text: ['대구시 수의사회 MOU'] },
			{ id: 18, date: '', text: ['인천시 수의사회 MOU'] },
		],
	},
	{
		id: 1,
		year: '2021 ~ 현재',
		color: RED,
		record: [
			{ id: 0, date: '21.08',   text: ['포포즈 부산점 오픈'] },
			{ id: 1, date: '21.09',   text: ['포포즈 광주점 오픈'] },
			{ id: 2, date: '22.03',   text: ['포포즈 김포점 오픈'] },
			{ id: 3, date: '22.09',  text: ['포포즈 양주점 오픈'] },
			{ id: 4, date: '22.10',  text: ['포포즈 세종점 오픈'] },
			{ id: 5, date: '23.01',  text: ['포포즈 화성점 오픈'] },
			{ id: 6, date: '23.06',  text: ['조선일보 선정 \'국가 브랜드 대상\''] },
			{ id: 7, date: '23.09',  text: ['서울시수의사협회 인증'] },
			{ id: 8, date: '24.04',   text: ['한국모바일서비스 \'멍과함께\' MOU 체결'] },
			{ id: 9, date: '',  text: ['포인핸드 MOU 체결'] },
			{ id: 10, date: '24.06',  text: ['조선일보 선정 \'소비자 추천 1위 브랜드\''] },
			{ id: 11, date: '', text: ['KT MOU 체결'] },
			{ id: 12, date: '', text: ['SJ산림조합상조 MOU 체결'] },
			{ id: 13, date: '24.08',  text: ['교원라이프 MOU 체결'] },
			{ id: 14, date: '24.09',  text: ['에이치와이 인수'] },
			{ id: 15, date: '',  text: ['신한카드 MOU 체결'] }
		],
	},
	/*
	{
		id: 2,
		year: '2018',
		color: RAINBOW_YELLOW,
		record: [
			{ id: 0, date: '01', text: ['바른 입양 캠페인 핑크박스 행사'] },
			{ id: 1, date: '05', text: ['교보 라이프 플래닛 MOU 체결'] },
			{ id: 2, date: '09', text: ['Petdoc V+lab 출시'] },
		],
	},
	{
		id: 3,
		year: '2019',
		color: RAINBOW_GREEN,
		record: [
			{ id: 0, date: '04', text: ['Petdoc V+ad 런칭'] },
			{ id: 1, date: '06', text: ['삼성전자, 서울시 수의사회와 3자 MOU체결'] },
			{ id: 2, date: '', text: ['아람휴비스 주식회사 MOU체결'] },
			{ id: 3, date: '07', text: ['Petdoc V+care 본점 오픈'] },
			{ id: 4, date: '', text: ['경남동물병원협회 MOU 체결'] },
			{ id: 5, date: '09', text: ['태국 Fly Goose와 수출 계약 체결'] },
		],
	},
	{
		id: 4,
		year: '2020',
		color: RAINBOW_BLUE,
		record: [
			{ id: 0, date: '01', text: ['경기도수의사회 MOU'] },
			{ id: 1, date: '03', text: ['Petdoc V+care 부산점 오픈'] },
			{ id: 2, date: '05', text: ['강원대학교 임상수의사회 MOU'] },
			{ id: 3, date: '', text: ['대전시 수의사회 MOU'] },
			{ id: 4, date: '', text: ['충청북도 수의사회 MOU'] },
			{ id: 5, date: '06', text: ['아기유니콘 200 기업 선정'] },
			{ id: 6, date: '07', text: ['부산광역시수의사회 MOU'] },
			{ id: 7, date: '', text: ['대구시 수의사회 MOU'] },
			{ id: 8, date: '', text: ['인천시 수의사회 MOU'] },
		],
	},
	{
		id: 5,
		year: '2021',
		color: RAINBOW_NAVY,
		record: [
			{ id: 0, date: '07', text: ['시리즈 C 투자 유치'] },
			{ id: 1, date: '12', text: ['Petdoc V+partners 런칭'] },
		],
	},
	{
		id: 6,
		year: '2022',
		color: RAINBOW_PURPLE,
		record: [{ id: 0, date: '06', text: ['Petdoc Four Paws 예정'] }],
	},
	*/
];
