import contactIcon01 from '../assets/icons/icon_sns_instagram.svg';
import contactIcon02 from '../assets/icons/icon_sns_youtube.svg';
import { OUTER_URLS } from '../constants/urls';

export const contactData = [
	{ id: 0, title: 'V+Partners', description: '총판/업무협약 문의', link: 'sell@petdoc.co.kr', tel: '02-6949-3148', },
	{ id: 1, title: '벳화점', description: '몰 입점/몰 일반 문의', link: 'vetmall@petdoc.co.kr', tel: '02-6956-3042', },
	{ id: 2, title: 'V+Ad', description: '동물병원 TV 광고/브이애드 설치 문의', link: 'vad@petdoc.co.kr', tel: '02-417-3002', },
	{ id: 3, title: 'V+Care', description: '브이케어 이용 문의', link: 'Kakao Talk', linkId: '(ID : v_care)', tel: '02-6949-3016, 02-6949-3038', },
	{ id: 4, title: '포포즈 일반 문의', description: '반려동물 장례식장 문의', link: 'fourpaws@petdoc.co.kr', tel: '070-5117-4556', },
	{ id: 5, title: '포포즈 제휴 문의', description: '포포즈 기업/일반/지자체 제휴 문의', link: 'fourpawspartner@petdoc.co.kr', tel: '02-6949-3016', },
	{ id: 6, title: '일반 문의', description: '기타 일반 문의', link: 'petdoc@petdoc.co.kr', tel: '02-6949-3024', },
	{ id: 7, title: '채용 문의', description: '채용 문의', link: 'recruit@petdoc.co.kr', tel: '02-6949-3004, 02-6949-3037', },
	{ id: 8, title: '고객센터', description: '서울특별시 서초구 강남대로79길 32', map: 'https://map.naver.com/v5/search/펫닥/place/38556155?c=14139620.1584721,4509683.9630234,15,0,0,0,dh', tel: '02-417-3002', desc: '월 - 금 (10:00-18:00)' },
];

export const contactSnsData = [
	{ id: 0, icon: contactIcon01, link: OUTER_URLS.FOURPAWS_INSTAGRAM },
	{ id: 1, icon: contactIcon02, link: OUTER_URLS.FOURPAWS_YOUTUBE },
];
