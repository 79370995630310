import React, { useState } from 'react';
import styled from '@emotion/styled';
import AppLogoImg from '../../assets/images/logo_btn.svg';
import AppQrImg from '../../assets/images/img_download.svg';
import { ZIndex } from '../../constants/zIndex';
import { RED } from '../../constants/colors';
import { pxToRem } from '../../utils/common';
import { getDesktopStyle, showOnDesktopStyle } from '../../styles/layout';
import { css } from '@emotion/react';

const AppPopup = () => {
	const [isVisible, setIsVisible] = useState(true);

	const handleClose = () => {
		setIsVisible(false);
	};
	return (
		<>
			{isVisible && ( // isVisible이 true일 때만 표시
				<AppPopupWrapper>
					<AppPopupInner>
						<Title>펫닥 앱 운영 중지 및 <br/>스캐너 사업 종료 안내</Title>
						<Desc>1. 더 나은 서비스 제공을 위해 펫닥앱의 사용이 일시 중단됩니다.<br />
							기존 상담 내역 확인이 불가하오니 양해 부탁드립니다.<br />
							<div style={{height:'14px'}}></div>
							2. 사업 운영 정책에 따라 스캐너 사업 종료가 결정되었습니다.<br />
							이에 지난 3개월간 관련 내용을 고지하였으며,<br />
							<b>2025년 3월 1일자</b>로 <b>스캐너 관련 사업이 완전 중단</b>됩니다.<br />
							본 서비스에 보내주신 관심과 이용에 감사드립니다.<br /><br />
							더 좋은 서비스로 찾아뵙겠습니다.</Desc>
						<MobileDesc>1. 더 나은 서비스 제공을 위해 펫닥앱의<br />
							사용이 일시 중단됩니다. 기존 상담 내역 확인이<br />
							불가하오니 양해 부탁드립니다.<br />
							<div style={{height:'12px'}}></div>
							2. 사업 운영 정책에 따라 스캐너 사업 종료가<br />
							결정되었습니다. 이에 지난 3개월간 관련 내용을 고지하였으며, <b>2025년 3월 1일자</b>로 <b>스캐너 관련<br />
							사업이 완전 중단</b>됩니다.  본 서비스에 보내주신<br />
							관심과 이용에 감사드립니다.<br />
							<div style={{height:'12px'}}></div>
							더 좋은 서비스로 찾아뵙겠습니다.</MobileDesc>
						<Buttons onClick={handleClose}>확인</Buttons>
					</AppPopupInner>
				</AppPopupWrapper>
			)}
		</>
	);
};

export default AppPopup;

const AppPopupWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0, 0.4);
	z-index: 9999;
	${getDesktopStyle(css`
	
	`)}
`;
const AppPopupInner = styled.div`
		width: calc(100% - ${pxToRem(60)});
		position: absolute;
		height: auto;
		left: ${pxToRem(30)};
		top: 50%;
		transform: translateY(-50%);
		background: #ffffff;
		padding: ${pxToRem(30)} ${pxToRem(43)} ${pxToRem(65)} ${pxToRem(43)};
	${getDesktopStyle(css`
		width: ${pxToRem(420)};
		height: ${pxToRem(365)};		
		padding: ${pxToRem(40)} ${pxToRem(32)} 0 ${pxToRem(32)};
		left: 50%;
		top: 50%;
		color:#2A303C;	
		transform: translate(-50%, -50%);	
		z-index: 9999;
		text-align:center;
	`)}
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: ${pxToRem(16)};
	margin-bottom: ${pxToRem(15)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(18)};
		margin-bottom: ${pxToRem(17)};
	`)}
`;
const Desc = styled.p`
	display:none;
	font-weight: 400;
	font-size: ${pxToRem(12)};
	margin-bottom: ${pxToRem(10)};
	color:#5C6272;
	line-height: ${pxToRem(20)};
	letter-spacing: -${pxToRem(0.5)};
	word-break: keep-all;
	text-align: left;
	
	${getDesktopStyle(css`
		display:block;
		font-size: ${pxToRem(14)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;
const MobileDesc = styled.p`
	
	font-weight: 400;
	font-size: ${pxToRem(12.5)};
	margin-bottom: ${pxToRem(10)};
	color:#5C6272;
	line-height: ${pxToRem(20)};
	letter-spacing: -${pxToRem(0.5)};
	word-break: keep-all; 

	${getDesktopStyle(css`
		display:none;
		font-size: ${pxToRem(14)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;
const Buttons = styled.button`
	font-weight: 700;
	width: 100%;
	font-size: ${pxToRem(16)}; 
	height: ${pxToRem(55)};
	line-height: ${pxToRem(47)};
	color:#fff;
	background: #FF5A5C;
	text-align:center;
	position:absolute;
	left:0;
	bottom:0;
	font-size: ${pxToRem(15)}; 

	${getDesktopStyle(css`
		font-size: ${pxToRem(14)}; 
		height: ${pxToRem(47)};
	`)}
`;
