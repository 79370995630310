import { IMAGE_BASE_URL } from '../constants/urls';

const VAD_IMG_01 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_01.svg`;
const VAD_IMG_02 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_02.svg`;
const VAD_IMG_03 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_03.svg`;
const VAD_IMG_04 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_04.svg`;
const VAD_IMG_05 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_05.svg`;
const VAD_IMG_06 = `${IMAGE_BASE_URL}/vad/20230620/vad_image_06.svg`;

export interface vAdDataItem {
	id: number;
	image: string;
	text: string;
	count: number;
	unit: string;
}

export const adData = [
	{ id: 0, image: VAD_IMG_01, text: '브이애드 계약 동물병원', count: 1111, unit: '여곳' },
	{ id: 1, image: VAD_IMG_02, text: '사이니지 설치수', count: 1316, unit: '여개' },
	{ id: 2, image: VAD_IMG_03, text: '월 광고 노출 인원', count: 733260, unit: '여명' },
	{ id: 3, image: VAD_IMG_04, text: '월 노출 평균 횟수', count: 2200000, unit: '회' },
	{ id: 4, image: VAD_IMG_05, text: '사이니지 시청률', count: 100, unit: '%' },
	{ id: 5, image: VAD_IMG_06, text: '광고 관련 검색 등 관여도', count: 65, unit: '% 이상' },
];

export const adDataUpdateDate = '*데이터 24년 10월 기준 / 오픈서베이 24년 1월 기준';
